import React, { useState } from 'react';
import Layout from '../components/organisms/layout/layout';
import NotFoundContent from '../components/molecules/notFoundContent/notFoundContent';
import Helmet from 'react-helmet';
import favicon from '../images/favicon.ico';
//import SEO from "../components/seo"

const NotFoundPage = () => {
  //<SEO title="404: Not found" />
  const [modalActive, setmodalActive] = useState(null)
  const menuActive = 2;
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Layout menuActive={menuActive}
          setmodalActive={setmodalActive}
          modalActive={modalActive}
          title={'404: Not found'}
          slug={'/404-not-found'}
      >
        <NotFoundContent />
      </Layout>
    </>
  );
};

export default NotFoundPage;
