import React from 'react';
import './alertMessagge.scss';
import Fab from '@mui/material/Fab'

const AlertMessagge = ({ icon, messagge, description, type, button }) => {
  return (
    <div className={`f-alert f-alert--${type}`}>
      {icon}
      <h2 className="f-h2">{messagge}</h2>
      <p className="f-p">{description}</p>
      <a
        href={() => isBrowser && "www.familia.com.co/"}
        className={'nostyle'}
      >
        <Fab
          variant="extended"
          size="medium"
          aria-label="add"
          className="f-content-important-redirection"
        >
          Ir al inicio
        </Fab>
      </a>
    </div>
  );
};

export default AlertMessagge;
