import React from 'react'
import AlertMessagge from '../alertMessagge/alertMessagge'
import NotFound from '../../../shared/assets/images/404.png'
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import './notFoundContent.scss'

const NotFoundContent = () => {
	const { isBrowser } = useBrowserMode()
	return (
		<section className='f-notfound-wrapper'>
			 <AlertMessagge
				icon={<img src={NotFound} alt='Not found' />}
				description='Lo sentimos, la página que buscabas no se encuentra disponible en este momento.'
				type='primary'
			/> 
		</section>
	)
}

export default NotFoundContent
